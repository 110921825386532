<template>
  <!-- New card user design -->

  <div class="panel" data-aos="fade-up">
    <div class="card_shadow">
      <div class="card_img">
        <div
          :style="{'background-image': 'url(' + `${getImage}` + ')'}"
          class="staff_img"
        />
      </div>
      <div class="card_txt">
        <p class="txt">
          <strong>{{ getMemberName }}</strong> {{ getMemberDescription }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
//import NekoSeparator from "@/components/NekoSeparator.vue";
export default {
  props: ["separator_text", "image", "text", "team"],
  //components: {"neko-separator": NekoSeparator },
  computed: {
    isTeamNull() {
      return this.team === undefined;
    },
    getImage() {
      let img = this.image;
      if (this.image === null || this.image === "") {
        img = `https://picsum.photos/400/200?random=${this.team.id}`;
      }
      return img;
    },
    getMemberDescription() {
      if (this.text == "thisCouldBeYouText")
        return this.$t("THIS_COULD_BE_YOU_TEXT");
      if (this.isTeamNull) return "";
      //had to do this due ot lack of API support for spanish at the moment, just change back to const lang = this.$i18n.locale.toUpperCase() when spanish is implemented
      const lang =
        this.$i18n.locale.toUpperCase() === "ES"
          ? "FR"
          : this.$i18n.locale.toUpperCase();
      if (
        this.team.description_of_member_locale === null ||
        this.team.description_of_member_locale === undefined
      )
        return this.text;
      if (lang === "EN") return this.text;
      return this.team.description_of_member_locale[lang];
    },
    getMemberName() {
      if (this.separator_text == "thisCouldBeYouTitle")
        return this.$t("THIS_COULD_BE_YOU");
      if (this.isTeamNull) return "";
      //had to do this due ot lack of API support for spanish at the moment, just change back to const lang = this.$i18n.locale.toUpperCase() when spanish is implemented
      const lang =
        this.$i18n.locale.toUpperCase() === "ES"
          ? "FR"
          : this.$i18n.locale.toUpperCase();
      if (
        this.team.full_name_locale === null ||
        this.team.full_name_locale === undefined
      )
        return this.separator_text;
      if (lang === "EN") return this.separator_text;
      return this.team.full_name_locale[lang];
    },
  },
};
</script>

<style scoped>
.panel {
  border-radius: 5px;
}
.card_shadow {
  border-radius: 5px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.staff_img {
  height: 300px;
  border-radius: 5px 5px 0 0;
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: cover;
}
.card_txt {
  background-color: white;
  padding: 30px;
  border-radius: 0 0 5px 5px;
}
.txt {
  font-size: 16px;
  text-align: justify;
  color: #3c1c35;
  margin: 0;
}
hr {
  margin-right: auto;
  margin-left: auto;
  border: none;
  border-top: 6px dotted #d67511;
  width: 75%;
}
.text_div {
  margin-right: auto;
  margin-left: auto;
  width: 75%;
}
.up {
  transform: translateY(-60px);
}
</style>
