<template>
  <div
    :class="reverse ? 'reverse_neko_paw' : 'neko_paw'"
    data-aos="zoom-out"
    data-aos-anchor-placement="bottom-bottom"
    data-aos-delay="300"
  >
    <img :src="require('../assets/animal-paw-print.svg')" />
  </div>
</template>
<script>
export default {
  props: ["reverse"],
};
</script>
<style scoped>
.neko_paw {
  height: 45px;
  width: 45px;
  transform: rotate(120deg) !important;
}
.reverse_neko_paw {
  height: 45px;
  width: 45px;
  transform: rotate(250deg) !important;
}
</style>
