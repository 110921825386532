<template>
  <div class="about">
    <neko-separator :separator_text="$t('ABOUT_US')"></neko-separator>
    <b-container class="textContainer">
      <h5 class="mx-5 topText">
        <strong>{{ $t("ABOUT_US_PARAGRAPH_1") }} </strong>
        <br />
        <br />
        <strong>{{ $t("ABOUT_US_PARAGRAPH_2") }} </strong>
      </h5>
      <br />
    </b-container>
    <neko-separator :separator_text="$t('WHAT_WE_BELIEVE_IN')"></neko-separator>
    <b-container>
      <div class="wrapper">
        <about-card
          :separator_text="$t('CUTTING_EDGE_CRAFT')"
          :image="require('../assets/icons-about/CuttingEdgeCraft.svg')"
          :text="$t('CUTTING_EDGE_CRAFT_TEXT')"
        ></about-card>
        <about-card
          :separator_text="$t('SELF_MANAGEMENT')"
          :image="require('../assets/icons-about/SelfManagement.svg')"
          :text="$t('SELF_MANAGEMENT_TEXT')"
        ></about-card>
        <about-card
          :separator_text="$t('SUSTAINABLE_DEVELOPMENT')"
          :image="require('../assets/icons-about/SustainableDevelopment.svg')"
          :text="$t('SUSTAINABLE_DEVELOPMENT_TEXT')"
        ></about-card>
        <about-card
          :separator_text="$t('CONSIDERATION_FOR_ALL')"
          :image="require('../assets/icons-about/Consideration.svg')"
          :text="$t('CONSIDERATION_FOR_ALL_TEXT')"
        ></about-card>

        <about-card
          :separator_text="$t('WHOLENESS')"
          :image="require('../assets/icons-about/Wholeness.svg')"
          :text="$t('WHOLENESS_TEXT')"
        ></about-card>
        <about-card
          :separator_text="$t('EMERGENT_PURPOSE')"
          :image="require('../assets/icons-about/EmergentPurpose.svg')"
          :text="$t('EMERGENT_PURPOSE_TEXT')"
        ></about-card>
      </div>
    </b-container>
    <div class="img_div">
      <div class="about_img mb-5">
        <b-carousel id="carousel-fade" :interval="7000" fade>
          <b-carousel-slide :img-src="require('../assets/team.jpg')" />
        </b-carousel>
      </div>
    </div>
    <neko-separator :separator_text="$t('OUR_MEMBERS')"></neko-separator>
    <div class="my-grid-user" v-if="loading">
      <user-card
        v-for="member in members"
        :key="member.id"
        :separator_text="member.full_name"
        :image="member.avatar_url"
        :text="member.description_of_member"
        :team="member"
        class="box"
      >
      </user-card>
      <router-link :to="{name: 'Career'}">
        <user-card
          :separator_text="'thisCouldBeYouTitle'"
          :image="require('../assets/favicon_cat2.png')"
          :text="'thisCouldBeYouText'"
        ></user-card>
      </router-link>
    </div>
    <div class="adress_panel">
      <div class="adress_text">
        <br />
        <b-img
          :src="require('../assets/icons-projects/Locational.svg')"
          fluid
          width="50"
          alt="Responsive image"
        ></b-img>
        <br />
        <h3 style="white-space: pre; text-align: center">
          <a
            href="https://www.google.com/maps/place/%E7%9C%9F%E5%A6%82%E5%A0%82%E3%82%A2%E3%83%93%E3%82%BF%E3%82%B7%E3%82%AA%E3%83%B3/@35.0199516,135.7913515,20.57z/data=!4m14!1m7!3m6!1s0x600108be9d184237:0x7d1230696e7ccb5e!2sNekologic!8m2!3d35.0198006!4d135.7914102!16s%2Fg%2F11f3d2m_nf!3m5!1s0x6001091d0c640001:0x17c77fd3caff38ca!8m2!3d35.0198531!4d135.7913732!16s%2Fg%2F11bxfs6y6g?entry=ttu"
          >
            {{ $t("ADDRESS") }}
          </a>
        </h3>
      </div>
      <custom-map :lat="35.019854" :lng="135.791464" />
    </div>
    <div class="adress_panel">
      <div class="adress_text">
        <br />
        <b-img
          :src="require('../assets/icons-projects/Locational.svg')"
          fluid
          width="50"
          alt="Responsive image"
        ></b-img>
        <br />
        <h3 style="white-space: pre; text-align: center">
          <a
            href="https://www.google.com/maps/place/37-45+Windsor+St,+Liverpool+L8+1XE,+Royaume-Uni/@53.3940686,-2.9696928,17z/data=!3m1!4b1!4m6!3m5!1s0x487b20e081b16299:0x7684844cf69727f1!8m2!3d53.3940686!4d-2.9696928!16s%2Fg%2F11c5jq5fhj?entry=ttu"
          >
            {{ $t("ADDRESS_UK") }}
          </a>
        </h3>
      </div>
      <custom-map :lat="53.3941" :lng="-2.969724" />
    </div>

    <div v-if="false">
      <neko-separator :separator_text="'COMPANY HISTORY'"></neko-separator>
      <neko-timeline />
    </div>
  </div>
</template>

<script>
import NekoSeparator from "@/components/NekoSeparator.vue";
import AboutCard from "@/components/AboutCard.vue";
import UserCard from "@/components/UserCard.vue";
import NekoTimeline from "@/components/NekoTimeline.vue";
import proxy from "@/services/proxy";
import CustomMap from "@/components/CustomMap.vue";

export default {
  name: "About",
  components: {
    "neko-separator": NekoSeparator,
    "about-card": AboutCard,
    "user-card": UserCard,
    "neko-timeline": NekoTimeline,
    "custom-map": CustomMap,
  },
  mounted() {
    this.fetchData();
    this.track();
  },
  data() {
    return {
      members: [],
      loading: false,
    };
  },
  methods: {
    track() {
      this.$gtag.pageview({
        page_path: "/about",
      });
    },
    async fetchData() {
      proxy
        .get("/api/public/users/team-members/", {})
        .then((resp) => {
          const members = resp?.data || null;
          if (members === null) {
            throw Error("Error: No Data: " + JSON.stringify(resp));
          }

          if (members.length === 0) {
            throw Error("No Member data found");
          }
          this.members = members.sort(() => Math.random() - 0.5);
          this.loading = true;
        })
        .catch((err) => {
          console.log(err);
          this.$gtag.exception({
            description: "About page did not fetch data",
            fatal: false,
          });
        });
    },
  },
};
</script>

<style scoped>
.my-grid-user {
  max-width: 1300px;
  margin: 20px auto;
  column-count: 4;
  column-gap: 1em;
  break-inside: avoid;
}
.box {
  display: inline-block;
  width: 100%;
  margin: 0 0 1em;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .wrapper {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .wrapper > * {
    margin-top: 1.2rem;
    width: 100%;
    max-width: 25rem;
  }
}
.wrapper {
  margin-bottom: 50px;
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 1rem;
}
.wrapper > * {
  width: 100%;
  max-width: 25rem;
}
.img_div {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
  background-color: #75cad5;
}
.about_img {
  overflow: hidden;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  width: 65%;
}

.textContainer {
  padding: 0px 160px;
}
.topText {
  text-align: center;
}
a {
  color: #d67511;
  cursor: pointer;
  text-decoration: none;
}
.hirring {
  width: 75%;
  text-align: justify;
  margin-right: auto;
  margin-left: auto;
  transform: translateY(-35px);
  color: #3c1c35;
}
.adress_panel {
  display: flex;
  color: whitesmoke;
  flex-wrap: wrap;
  background-color: #87314d;
  border-top: 5px solid #f4b346;
}
.adress_text {
  width: 50%;
  padding: 5%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1300px) {
  .my-grid-user {
    margin: 20px 20px;
    column-count: 3;
  }
}
@media only screen and (max-width: 990px) {
  .wrapper {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
}
@media only screen and (max-width: 831px) {
  .adress_text {
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .textContainer {
    padding: 0px 0px;
  }
  .my-grid-user {
    column-count: 2;
  }
  .about_img {
    width: 80%;
  }
}
@media only screen and (max-width: 540px) {
  .my-grid-user {
    column-count: 1;
  }
}
@media only screen and (max-width: 425px) {
  .adress_text {
    height: 200px;
    padding-bottom: 0px;
  }
  h3 {
    font-size: 25px;
  }
}
@media only screen and (max-width: 395px) {
  .wrapper {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}
</style>
