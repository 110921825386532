<template>
  <div class="map_img">
    <l-map
      :center="center"
      :zoom="zoom"
      @update:center="centerUpdate"
      @update:zoom="zoomUpdate"
      style="height: 100%"
    >
      <l-tile-layer :url="url" :attribution="attribution"> </l-tile-layer>
      <l-marker :lat-lng="markerLatLng">
        <l-tooltip>{{ $t("HELLO") }} </l-tooltip>
      </l-marker>
    </l-map>
    <!-- <div
      id="iddiv"
      style="background-color:red;"
    ><img src="https://img.icons8.com/office/40/000000/marker.png" /></div> -->
  </div>
</template>
<script>
import {latLng} from "leaflet";
import {LMap, LTileLayer, LMarker, LTooltip} from "vue2-leaflet";

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
  },
  props: ["lat", "lng"],
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 18,
      center: latLng(this.lat, this.lng),
      markerLatLng: latLng(this.lat, this.lng),
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      currentZoom: 11.5,
      currentCenter: latLng(this.lat, this.lng),
    };
  },

  mounted() {
    setTimeout(function () {
      window.dispatchEvent(new Event("resize"));
    }, 250);
  },
  methods: {
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
  },
};
</script>
<style scoped>
.map_img {
  z-index: 10;
  height: 300px;
  width: 500px;
  border: 5px solid #f4b346;
  border-radius: 5px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 25px;
  margin-bottom: 25px;
}
@media only screen and (max-width: 455px) {
  .map_img {
    height: 300px;
    width: 350px;
  }
}
</style>
