<template>
  <div class="about_card">
    <div>
      <b-img :src="image" center class="about_card_img"></b-img>
      <h5 style="text-align: center; text-transform: uppercase">
        <strong>{{ separator_text }}</strong>
      </h5>
    </div>
    <p class="txt">{{ text }}</p>
  </div>
</template>

<script>
export default {
  props: ["separator_text", "image", "text"],
};
</script>

<style scoped>
.about_card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.about_card_img {
  height: 225px;
  width: 255px;
  margin-top: 10px;
  margin-bottom: 15px;
  margin-left: 5px;
  margin-right: 5px;
}
.txt {
  padding: 5%;

  text-align: center;
  color: #3c1c35;
  white-space: pre-line;
}
@media only screen and (max-width: 660px) {
  .about_card_img {
    height: 125px;
    width: 155px;
  }
  h5 {
    font-size: 18px;
  }
  .txt {
    font-size: 12px;
  }
}
</style>
