<template>
  <b-container class="neko_timeline">
    <div class="time_step">
      <h2>2017</h2>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam
        repellendus nam recusandae veritatis natus quam et, perspiciatis,
        voluptas odio totam, hic quidem. Unde vel, vitae repellendus corrupti
        dolorum sunt maxime!
      </p>
    </div>
    <div class="paw_grid">
      <NekoPaw class="paw" style="top: 0%; left: 0%" />
      <NekoPaw class="paw" style="top: 7%; left: 10%" />
      <NekoPaw class="paw" style="top: 35%; left: 2%" />
      <NekoPaw class="paw" style="top: 40%; left: 13%" />
      <NekoPaw class="paw" style="top: 45%; left: 20%" />
      <NekoPaw class="paw last1" style="top: 70%; left: 17%" />
      <NekoPaw class="paw last2" style="top: 80%; left: 25%" />
    </div>
    <div class="time_step_2">
      <h2>2018</h2>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam
        repellendus nam recusandae veritatis natus quam et, perspiciatis,
        voluptas odio totam, hic quidem. Unde vel, vitae repellendus corrupti
        dolorum sunt maxime!
      </p>
    </div>
    <div class="paw_grid_2">
      <NekoPaw :reverse="true" class="paw" style="top: 7%; right: 10%" />
      <NekoPaw :reverse="true" class="paw" style="top: 35%; right: 2%" />
      <NekoPaw :reverse="true" class="paw" style="top: 40%; right: 13%" />
      <NekoPaw :reverse="true" class="paw" style="top: 45%; right: 20%" />
      <NekoPaw :reverse="true" class="paw" style="top: 70%; right: 17%" />
      <NekoPaw :reverse="true" class="paw" style="top: 80%; right: 25%" />
    </div>
    <div class="time_step">
      <h2>2019</h2>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam
        repellendus nam recusandae veritatis natus quam et, perspiciatis,
        voluptas odio totam, hic quidem. Unde vel, vitae repellendus corrupti
        dolorum sunt maxime!
      </p>
    </div>
  </b-container>
</template>
<script>
import NekoPaw from "@/components/NekoPaw.vue";
export default {
  components: {
    NekoPaw,
  },
};
</script>
<style scoped>
.time_step {
  width: 50%;
}
.time_step_2 {
  width: 50%;
  margin-left: 50%;
  margin-top: -5%;
}
.paw_grid {
  width: 50%;
  height: 300px;
  display: flex;
}
.paw_grid_2 {
  width: 50%;
  height: 300px;
  display: flex;
  flex-direction: row-reverse;
}
.paw {
  position: relative;
}
@media only screen and (max-width: 450px) {
  .last2 {
    display: none;
  }
}
@media only screen and (max-width: 395px) {
  .last1 {
    display: none;
  }
}
</style>
